import { useKeenSlider } from 'keen-slider/react'
import { useMediaQuery } from '@kaliber/use-media-query'
import { easeInOutQuart } from '/machinery/easings'
import { trackInteractionSlided } from '/machinery/trackInteractionSlided'
import { useInterval } from '/machinery/useInterval'
import { ImageCover } from '/features/buildingBlocks/Image'

import 'keen-slider/keen-slider.min.css'
import media from '/cssGlobal/media.css'
import styles from './SliderWithMedia.css'

export function SliderWithMedia({ images, title  }) {
  const [currentSlide, setCurrentSlide] = React.useState(0)
  const [loaded, setLoaded] = React.useState(false)
  const [autoPlay, setAutoPlay] = React.useState(true)
  const isMd = useMediaQuery(`(min-width: ${media.breakpointMd})`)
  const hasMultipleImages = images?.length > 1
  const slides = {
    perView: isMd ? (1 / 0.9) : (hasMultipleImages ? 1.5 : 1.1),
    spacing: isMd && hasMultipleImages ? 0 : 10,
  }
  const delay = 4000
  const duration = 300

  const [sliderRef, instanceRef] = useKeenSlider({
    slides,
    loop: true,
    selector: x => x.childNodes,
    defaultAnimation: {
      duration,
      easing: easeInOutQuart,
    },
    created() { setLoaded(true) },
    animationEnded() { setAutoPlay(true) },
    dragStarted() { setAutoPlay(false) },
    dragEnded(s) {
      setAutoPlay(true)
      trackInteractionSlided({ index: s.track.details.rel, title, action: 'dragged' })
    },
    slideChanged(s) {
      const { details } = s.track
      const { abs, slides } = details
      const slide = (abs + slides.length) % slides.length

      setCurrentSlide(slide)
    },
  })

  useInterval(
    () => {
      if (autoPlay && hasMultipleImages && instanceRef?.current) {
        instanceRef.current.moveToIdx(currentSlide + 1, true)
      }
    },
    delay
  )

  React.useEffect(
    () => {
      instanceRef.current.update()
    },
    [instanceRef, images, isMd, hasMultipleImages]
  )

  return (
    <div className={styles.component}>
      <div ref={sliderRef} className={cx('keen-slider', styles.slider)}>
        {images && images.map((image, index) => (
          <div key={image._key} className={cx(styles.slide, index === 0 && styles.firstSlide, 'keen-slider__slide')}>
            <ImageCover aspectRatio={9 / 16} {...{ image }} layoutClassName={styles.imageMobile} />
            <ImageCover aspectRatio={16 / 9} {...{ image }} layoutClassName={styles.imageDesktop} />
          </div>
        ))}
      </div>
      {loaded && instanceRef.current && hasMultipleImages && (
        <Controls
          layoutClassName={styles.controls}
          onClick={(index) => {
            instanceRef.current.moveToIdx(index)
            trackInteractionSlided({ index, title, action: 'clicked' })
          }}
          {... { images, currentSlide }}
        />
      )}
    </div>
  )
}

function Controls({ images, layoutClassName, currentSlide, onClick }) {
  return (
    <div className={cx(styles.componentControls, layoutClassName)}>
      <div className={styles.innerControls}>
        {images && images.map((image, index) => (
          <button
            aria-label='control-button'
            data-x={`go-to-slide- ${index}`}
            key={index}
            onClick={() => { onClick(index) }}
            className={cx(styles.button, currentSlide === index && styles.isActive )}
          />
        ))}
      </div>
    </div>
  )
}
